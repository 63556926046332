import api from '../helpers';

export default {
  create: {
    key: 'addRoutes',
    query: (data) => {
      return api.post('/api/routes', {
        headers: {
          'Content-Type': 'application/json',
        },
        data,
      });
    },
  },
  get: {
    key: 'fetchRoutes',
    query: (id, field = null) => {
      // can fetch by facility id (default) or leak id (field = 'leakId')
      const params = field ? { relation: field } : {};
      return api.get(`/api/routes/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    mapper: ({ data }) => {
      return data.map((model) => model);
    },
  },
  getStats: {
    key: 'fetchRouteStats',
    query: (routeId) => {
      return api.get(`/api/routes/stats/${routeId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
    mapper: ({ data }) => {
      return data;
    },
  },
  edit: {
    key: 'editRoute',
    query: (id, data) => {
      return api.put(`/api/routes/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data,
      });
    },
  },
  delete: {
    key: 'deleteRoute',
    query: (id) => {
      return api.delete(`/api/routes/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  },
};
