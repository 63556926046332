import { formatDates } from 'src/utils/display';
import api from '../helpers';
import generateSlug from 'src/utils/generateSlug';

const mapper = (model) => ({
  id: model.id,
  companySlug: model.slug,
  companyName: model.name,
  userTotal: (model.stats && model.stats.totalUsers) || 0,
  numAvailAdminSeats: model.numAvailAdminSeats,
});

export default {
  list: {
    key: 'fetchCompaniesList',
    query: (offset, limit) => {
      return api.get('/api/companies', {
        headers: {
          'Content-Type': 'application/json',
        },
        params: { offset, limit },
      });
    },
    mapper: ({ data }) =>
      data.reduce((acc, model) => {
        let ownedBy = null;
        if (Array.isArray(model.ownedBy) && model.ownedBy.length > 0) {
          ownedBy = model.ownedBy[model.ownedBy.length - 1];
        }

        return {
          ...acc,
          [model.id]: {
            id: model.id,
            companySlug: model.slug,
            companyName: model.name,
            managedBy: model.managedBy,
            ownedBy,
            owners: model.ownedBy,
            adminSeats: {
              max: model.numMaxAdminSeats || 0,
              available: model.numAvailAdminSeats || 0,
            },
            isCreateAccountBlocked: model.isCreateAccountBlocked || false,
            country: model.country,
            currency: model.currency,
            hasChildren: model.hasChildren,
            language: model.language,
            industry: model.industry,
            systemPressureSetpoint: model.systemPressureSetpoint,
            systemMeasuredFlow: model.systemMeasuredFlow,
            dateTimeRunningState: model.dateTimeRunningState,
          },
        };
      }, {}),
  },
  createByAdmin: {
    key: 'addCompany',
    query: (name, companyId, countryId, currency, language, industry) => {
      return api.post('/api/companies', {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          name,
          companySlug: generateSlug(name),
          parentCompanyId: companyId,
          countryId,
          currency,
          language,
          industry,
        },
      });
    },
  },
  createBySerial: {
    key: 'addCompanyWithSerial',
    query: ({ firstName, lastName, email, password, serial, companyName }) => {
      return api.post('/api/companies/serial', {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          firstName,
          lastName,
          email,
          password,
          serial,
          companyName,
        },
      });
    },
  },
  edit: {
    key: 'editCompany',
    query: (id, name, countryId, currency, language, industry) => {
      return api.put(`/api/companies/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: { name, countryId, currency, language, industry },
      });
    },
  },
  editCompanyCompressor: {
    key: 'editCompanyCompressor',
    query: (id, data) => {
      return api.put(`/api/companies/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data,
      });
    },
  },
  delete: {
    key: 'deleteCompany',
    query: (id) => {
      return api.delete(`/api/companies/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  },
  get: {
    key: 'fetchCompany',
    query: (id) => {
      return api.get(`/api/companies/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
    mapper: ({ data }) => mapper(data),
  },
  users: {
    key: 'fetchCompanyUsers',
    query: (id, offset, limit) => {
      return api.get(`/api/companies/${id}/users`, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: { offset, limit },
      });
    },
    mapper: ({ data }) => {
      return data.map((model) => ({
        id: model.id,
        companySlug: model.company?.slug,
        companyId: model.company?.id,
        companyName: model.company?.name,
        firstName: model.name.first,
        lastName: model.name.last,
        email: model.email,
        deviceId: model.deviceId,
        role: model.roles[0],
        active: model.isActive,
        versions: {
          software: model.versions?.software,
          firmware: model.versions?.firmware,
        },
        createdAt: model.createdAt ? formatDates(model.createdAt) : '-',
      }));
    },
  },
  sales: {
    key: 'fetchCompanyUsersSalesList',
    query: (id, offset, limit) => {
      return api.get(`/api/companies/${id}/users/sales`, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: { offset, limit },
      });
    },
    mapper: ({ data }) => {
      return data.map((model) => ({
        id: model.id,
        firstName: model.firstName,
        lastName: model.lastName,
        email: model.email,
        deviceId: model.deviceId,
        role: model.role,
        active: model.isActive,
        createdAt: model.createdAt ? formatDates(model.createdAt) : '-',
        companyName: model.companyName,
      }));
    },
  },
  stats: {
    key: 'fetchCompanyStats',
    query: (id, inherited = true) => {
      return api.get(`/api/companies/${id}/statistics${inherited ? '?includeSubCompanies' : ''}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
  },
  setEssential: {
    key: 'setCompanySettings',
    query: (id, active, inherited) => {
      return api.put(`/api/companies/${id}/settings`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          essential: { active, inherited },
        },
      });
    },
  },
};
