export default {
  translation: {
    commonUsedWords: {
      login: 'Iniciar sesión',
      email: 'Dirección de correo electrónico',
      password: 'Contraseña',
      emailIsRequired: 'Se requiere correo electrónico',
      mustBeAValidEmail: 'Debe ser un correo electrónico válido',
      deviceSerialNumber: 'Número de serie del dispositivo',
      next: 'Siguiente',
      success: 'Éxito',
      submit: 'Enviar',
      firstName: 'Nombre',
      firstNameIsRequired: 'Se requiere nombre',
      lastName: 'Apellido',
      lastNameIsRequired: 'Se requiere apellido',
      save: 'Guardar',
      cancel: 'Cancelar',
      loading: 'Cargando...',
      yes: 'Sí',
      no: 'No',
      clear: 'Limpiar',
      confirm: 'Confirmar',
      noOption: 'Sin opciones',
      openText: 'Abrir',
      edit: 'Editar',
      logout: 'Cerrar sesión',
      latitude: 'Latitud',
      longitude: 'Longitud',
      ok: 'Vale',
      okay: 'Está bien',
      done: 'Hecho',
    },
    api: {
      auth: {
        fieldsAreMissing: 'Faltan campos obligatorios',
        userNotFound: 'Usuario no encontrado',
        missingRefreshToken: 'Falta el token de actualización',
        cannotRefreshToken: 'No se puede actualizar el token',
      },
      helpers: {
        error: 'Error',
        invalidHTTPMethod: 'Método HTTP no válido',
      },
    },
    components: {
      confirmEmail: {
        confirmEmail: 'Confirmar dirección de correo electrónico',
        confirmEmailIsRequired: 'Se requiere confirmar el correo electrónico',
        emailNotMatched: 'Las entradas de correo electrónico no coinciden',
      },
      confirmPassword: {
        confirmPassword: 'Confirmar contraseña',
        confirmPasswordIsRequired: 'Se requiere confirmar la contraseña',
        newPasswordIsRequired: 'Se requiere una contraseña nueva',
        showPassword: 'Mostrar contraseña',
        passwordLength: 'Las contraseñas deben tener al menos 6 caracteres',
        passwordContainSpaces: 'La contraseña no debe contener espacios',
        passwordNotMatch: 'Las entradas de contraseña no coinciden',
        newPassword: 'Nueva contraseña',
      },
      confirmModal: {
        areYouSure: '¿Estás seguro?',
      },
      appliedFilters: {
        between: 'Entre {{firstValue}} y {{secondValue}}',
        bottom: 'Inferior {{primaryValue}}%',
        top: 'Superior {{primaryValue}}%',
        activeFilters: 'Filtros activos',
        active: 'activo',
        closed: 'cerrado',
      },
      editTable: {
        editTable: 'Editar tabla',
        selectDeselect: 'Seleccionar/Deseleccionar valores de la tabla',
        resetToDefault: 'Restablecer a los valores predeterminados',
      },
      errorBoundary: {
        oops: '¡Vaya! Algo inesperado ocurrió.',
        tryAgain: '¡Inténtalo de nuevo! Si el problema persiste, comuníquese con su administrador.',
        refresh: 'Actualizar',
      },
      noResultFound: {
        noResultFoundTryAgain: 'Lo siento, no se encontraron resultados. Por favor, inténtelo de nuevo.',
      },
      permissionGuard: {
        invalidPermission: 'Estructura de permisos no válida, se esperan 3 elementos en la matriz',
      },
      preview: {
        findInView: 'Buscar en vista',
        showDetails: 'Mostrar detalles',
        closeEsc: 'Cerrar (Esc)',
        prevLeftArrow: 'Anterior (Flecha izquierda)',
        nextRightArrow: 'Siguiente (Flecha derecha)',
        proceedWithoutSaving: 'Navegar sin guardar',
        continueEditing: 'Continuar editando',
        unsavedChanges: {
          partOne: 'Atención: ',
          partTwo: 'Tienes ',
          partThree: 'CAMBIOS NO GUARDADOS',
          partFour: '. Navegar sin guardar descartará todos los cambios. ¿Estás seguro?',
        },
      },
      printRecord: {
        title: 'Registro de evento de fuga',
        date: 'Fecha:',
        location: 'Ubicación',
        leakDetails: 'Detalles de la fuga',
        leakParameters: 'Parámetros de la fuga',
        energyLoss: 'Pérdida de energía',
        leakSourceEquipment: 'Equipo fuente de la fuga',
        notes: 'Notas',
        leakEventStatus: 'Estado del evento de fuga',
        requiredAction: 'Acción requerida',
        leakSourceImage: 'Imagen de la fuente de la fuga',
        smartLeakTag: 'Etiqueta de fuga inteligente',
        lossPerYear: 'Pérdida ({{currency}}/año)',
      },
      assignedAction: {
        actionHeader: {
          deleteAssignmentSuccess: 'La asignación ha sido eliminada',
          deleteAssignmentError: 'La asignación no se puede eliminar. Por favor, inténtelo de nuevo más tarde',
          currentlyAssigned: 'Acción asignada actualmente',
          repairedBy: 'Reparado por',
          text: 'Vas a eliminar esta asignación. Esta acción no se puede deshacer',
        },
        actionSaveDate: 'Última guardado',
      },
      visibleColumnsPopper: {
        tableValues: 'Seleccionar / Deseleccionar valores de la tabla',
      },
    },
    constants: {
      qualifiers: {
        EQUAL_TO: 'Igual a',
        LESS_THAN: 'Menos que',
        GREATER_THAN: 'Mayor que',
        LESS_THAN_OR_EQUAL_TO: 'Menor o igual a',
        GREATER_THAN_OR_EQUAL_TO: 'Mayor o igual a',
        BETWEEN_A_AND_Y: 'Entre',
        TOP_PERCENT: 'Superior "x"%',
        BOTTOM_PERCENT: 'Inferior "x"%',
      },
      recordFields: {
        CREATED_AT: 'Creado el',
        RECORD_ID: 'Número de ID de registro',
        DOCUMENT_ID: 'Número de ID de registro',
        DOLLARS_PER_YEAR: 'Pérdida',
        GAS_COMPONENT: 'Gas/Componente',
        LEAK_TYPE: 'Perfil de tipo de fuga',
        DATE_TIME: 'Fecha/Hora',
        DB_PEAK: 'dB pico',
        COMPANY: 'Empresa',
        NOTES: 'Notas',
        USER: 'Usuario',
        EQUIPMENT_TAG: 'Etiqueta de equipo/componente',
        FLOW: 'Flujo (m³/h)',
        EQUIPMENT: 'Unidad/Equipo',
        ROUTE: 'Ruta',
        ROUTE_LOCATION: 'Ubicación de la Ruta',
        PROJECT_SURVEY: 'Ref. de registro de auditoría/encuesta',
        OUTAGE_STATUS: '¿Se requiere corte/parada?',
        FACILITY: 'Instalación/Sitio',
        ACTION_STATUS: 'Estado de la acción',
        SERVICE_TYPE: 'Servicio/Tipo',
        DB_ACTIVE: 'dB activo',
        REFERENCE_ID: "proyecto de #/ID d'encuesta",
        AMBIENT_TEMPERATURE: 'Temperatura ambiente (°C)',
        LEAK_PRESSURE: 'Presión (kPag)',
        LEAK_TEMPERATURE: 'Temperatura del gas (°C)',
        LEAK_CATEGORY: 'Categoría de fuga',
        DISTANCE: 'Distancia (m)',
        GAS_COST: 'Costo del gas ($/m³)',
        COMPRESSOR_FLOW: 'm³/h por BHP',
        DEVICE_ID: 'ID del dispositivo',
        COMPRESSOR_BHP: 'BHP del compresor',
        COMPRESSOR_TYPE: 'Tipo de compresor',
        MOTOR_EFFICIENCY: 'Eficiencia del motor (%)',
        ENERGY_COST: 'Costo energético (por  kWh)',
        HOURS: 'Horas de funcionamiento por año',
        LOCATION: 'Ubicación de la fuga',
        LAST_MODIFIED: 'Última fecha guardada',
        POWER_LOAD: '% Potencia a plena carga',
        CLIENT: 'Cliente',
        SERVICE: 'Servicio',
        CAPACITY: '% Capacidad',
        EQUIPMENT_TYPE: 'Tipo de equipo/componente',
        LEAK_TAG: 'Etiqueta de fuga #/Ref. de trabajo #',
        REPAIRED_BY: 'Reparado por',
        ALTITUDE: 'Altitud (m)',
        REQUIRED_ACTION: 'Acción requerida',
        FREQUENCY_LOW_BOUND: 'Frecuencia (baja)',
        FREQUENCY_HIGH_BOUND: 'Frecuencia (alta)',
        SENSITIVITY: 'Sensibilidad',
        KWH_PER_YEAR: 'kWh/año',
        ASSIGNED_BY: 'Asignado por',
        ASSIGNED_TO: 'Asignado a',
        ASSIGNED_DATE: 'Fecha de asignación',
        LOSS_PER_YEAR: 'Pérdida (por año)',
        ASSIGNED: 'Asignado',
        CREATOR_USER_ID: 'ID de usuario',
        CURRENCY: 'Moneda',
        COMPLETED_ON: 'Completado el',
        IMAGES: 'Imágenes de la fuente de fuga',
        ACTION_STATUS_MODIFIED: 'Estado de la acción modificado',
      },
      IMPERIAL_LABELS: {
        FLOW: 'Flujo (cfm)',
        AMBIENT_TEMPERATURE: 'Temperatura ambiente (°F)',
        LEAK_PRESSURE: 'Presión (psi)',
        LEAK_TEMPERATURE: 'Temperatura del gas (°F)',
        DISTANCE: 'Distancia (ft)',
        GAS_COST: 'Costo del gas ($/ft³)',
        COMPRESSOR_FLOW: 'cfm por BHP',
        ALTITUDE: 'Altitud (ft)',
      },
      userFields: {
        EMAIL: 'Correo electrónico',
        ROLE: 'Rol',
        DEVICE_ID: 'ID del dispositivo',
        DATE_TIME: 'Creado',
        ACTIVE: 'Estado',
        SOFTWARE_VERSION: 'Versión del software',
        FIRMWARE_VERSION: 'Versión del firmware',
        FIRST_NAME: 'Nombre',
        LAST_NAME: 'Apellido',
        COMPANY_SLUG: 'Empresa',
        COMPANY_NAME: 'Empresa',
      },
      auditFields: {
        documentId: 'Número de ID de registro',
        companyName: 'Nombre de la empresa',
        auditorName: 'Nombre del auditor',
        auditorEmail: 'Correo electrónico del auditor',
        createdAt: 'Creado el',
        updatedAt: 'Actualizado el',
        comment: 'Comentarios',
        isLocked: 'Bloqueo de auditoría',
      },
      deviceFields: {
        DEVICE_ID: 'ID del dispositivo',
        ACTIVE: 'Estado',
        REGISTERED_ON: 'Activado el',
        EVENT_COUNT: 'Registros',
        UNIQUE_COMPANIES: 'Empresas',
        LAST_USED_AT: 'Última utilización el',
        LAST_USED_BY_NAME: 'Último nombre usado',
        LAST_USED_BY_EMAIL: 'Último correo electrónico usado',
        LOCATION: 'Última ubicación usada',
        FIRMWARE_VERSION: 'Versión del firmware',
      },
      enpisFields: {
        labels: {
          NO_OF_LEAKS_FOUND: 'Número de fugas encontradas',
          NO_OF_LEAKS_REPAIRED: 'Número de fugas reparadas',
          PERCENT_LEAKS_REPAIRED: '% Fugas reparadas',
          VOL_FLOW_FOUND: 'Flujo de volumen encontrado (m³/h)',
          VOL_FLOW_REPAIRED: 'Flujo de volumen reparado (m³/h)',
          PERCENT_VOL_FLOW_REPAIRED: '% Flujo reparado',
          VALUE_FOUND: 'Valor encontrado',
          VALUE_REPAIRED: 'Valor reparado',
          ENERGY_SAVING_OPPORTUNITY_FOUND: 'Oportunidad de ahorro energético (encontrado) (kWh)',
          ENERGY_SAVING_OPPORTUNITY_PERCENT: 'Oportunidad de ahorro energético % (encontrado vs calculado o medido)',
          ENERGY_SAVING_REALIZED_FIXED: 'Ahorro energético realizado (reparado) (kWh)',
          ENERGY_SAVING_REALIZED_PERCENT: '% Ahorro energético realizado (reparado vs calculado o medido)',
          SYSTEM_CONTROL_PRESSURE_REDUCED: 'Presión de control del sistema reducida (kPag)',
        },
        labelsImperial: {
          VOL_FLOW_FOUND: 'Flujo de volumen encontrado (cfm)',
          VOL_FLOW_REPAIRED: 'Flujo de volumen reparado (cfm)',
          ENERGY_SAVING_OPPORTUNITY_FOUND: 'Oportunidad de ahorro energético (encontrado) (BTU)',
          ENERGY_SAVING_REALIZED_FIXED: 'Ahorro energético realizado (reparado) (BTU)',
          SYSTEM_CONTROL_PRESSURE_REDUCED: 'Presión de control del sistema reducida (psi)',
        },
        columns: {
          PERFORMANCE_INDICATOR: 'Indicador de rendimiento',
          ENPI_VALUE: 'Valor EnPI',
          ENB_VALUE: 'Valor EnB',
          SUCCESS_THRESHOLD: 'Umbral de éxito (%)',
        },
      },
      assignedAction: {
        assignedTo: 'Asignado a',
        assignedBy: 'Asignado por',
        assignedDate: 'Fecha de asignación',
        requestReassign: 'Solicitar reasignación',
        actionStatus: 'Estado de la acción',
        partsNeeded: 'Parte requerida',
        productionOutage: 'Solicitud de apagado',
        timeToRepair: 'Tiempo de reparación',
        repairCost: 'Costo de reparación',
        closeOutRef: 'Ref. de cierre',
        'assignmentRecord.documentId': 'ID de registro',
        assignmentId: 'ID de asignación',
        owner: 'Propietario',
        companyName: 'Empresa',
        assignmentActive: 'Estado de la asignación',
        'assignmentRecord.leakStatus.building': 'Unidad/Equipo',
        'assignmentRecord.leakStatus.facility': 'Instalación',
        'assignmentRecord.leakStatus.routeName': 'Ruta',
        'assignmentRecord.leakStatus.leakTag': 'Etiqueta de fuga #/Ref. de trabajo #',
        'assignmentRecord.currency': 'Moneda',
      },
      AAR_FIELD_LABEL: {
        NOTES: 'Acción tomada',
        CLOSE_OUT_REF: 'Referencia de cierre de acción n.º',
        PARTS_NEEDED: 'Nueva pieza/componente requerido',
        PRODUCTION_OUTAGE: 'Producción interrumpida',
        REPAIR_COST: 'Costo ($)',
        REQUEST_REASSIGN: 'Solicitar reasignación',
        ACTION_STATUS: 'Estado de la acción',
        TIME_TO_REPAIR: 'Tiempo de reparación (minutos)',
      },
      leak: {
        chartsLabels: {
          FLOW_BY_LEAK: 'Flujo por categoría de fuga',
          LOSS_BY_LEAK: 'Pérdida por categoría de fuga',
          TAGS_BY_LEAK: 'Etiquetas por categoría de fuga',
          AVG_FLOW_BY_LEAK: 'Flujo promedio por categoría de fuga',
          SAVING_OPT_BY_REPAIR: 'Oportunidad y ahorros por tiempo de reparación',
          FLOW_REPAIR_TIME: 'Flujo por tiempo de reparación',
          LOSS_REPAIR_TIME: 'Pérdida por tiempo de reparación',
          FLOW_REPAIR_PROG: 'Flujo por progreso de reparación',
          TOTAL_REPAIR_PROG: 'Pérdida por progreso de reparación',
          FLOW_BY_EQUIP_COMP: 'Flujo por equipo/componente',
          LOSS_BY_EQUIP_COMP: 'Pérdida por equipo/componente',
          TAGS_BY_EQUIP_COMP: 'Etiquetas por equipo/componente',
        },
        leakCategoryLabels: {
          LOW: 'Bajo',
          MEDIUM: 'Medio',
          HIGH: 'Alto',
        },
        actionStatus: {
          OPEN: 'Abrir',
          COMPLETE: 'Completo',
        },
        equipmentTypes: {
          CONNECTOR: 'Conector',
          CONTROL_VALVE: 'Válvula de control',
          HOSE: 'Manguera',
          TUBE_FITTING: 'Conector de tubo',
          METER: 'Medidor',
          OPEN_ENDED_LINE: 'Línea abierta',
          PRESSURE_RELIEF_VALVE: 'Válvula de alivio de presión',
          PUMP_SEAL: 'Sello de bomba',
          REGULATOR: 'Regulador',
          VALVE: 'Válvula',
          LEVEL_CONTROLLER: 'Controlador de nivel',
          POSITIONER: 'Posicionador',
          PRESSURE_CONTROLLER: 'Controlador de presión',
          TRANSDUCER: 'Transductor',
          GENERIC_PNEUMATIC_INSTRUMENT: 'Instrumento neumático genérico',
          GENERIC_RECIPROCATING_COMPRESSOR: 'Compresor alternativo genérico',
          GENERIC_CENTRIFUGAL_COMPRESSOR: 'Compresor centrífugo genérico',
        },
        mainGasValues: {
          AIR: 'Aire',
          METHANE: 'Metano (CH4)',
          CARBON_DIOXIDE: 'Dióxido de carbono (CO2)',
          HYDROGEN: 'Hidrógeno (H2)',
          HELIUM: 'Helio (He)',
          NITROGEN: 'Nitrógeno (N2)',
          REFRIGERANT: 'Refrigerante (HFCs)',
          ARGON: 'Argón (Ar)',
          OXYGEN: 'Oxígeno (O)',
          WATER: 'Agua (Vapor)',
        },
        leakTypeValues: {
          DEFAULT: 'Ninguno seleccionado',
          HOLE: 'Agujero',
          CRACK: 'Grieta',
          FITTING: 'Conector',
          FLANGE: 'Brida',
          'GLAND/SEAL': 'Gland/Seal', // Not fully translated
          THREAD: 'Rosca',
          'TUBE/HOSE': 'Tubo/Manguera',
        },
        serviceValues: {
          COMPRESSED_AIR: 'Aire comprimido',
          VACUUM: 'Vacío',
          STEAM: 'Vapor',
          PROCESS_GAS: 'Gas de proceso',
          LIQUID: 'Líquido',
        },
        compressorTypesValues: {
          ROTARY_SCREW: 'Tornillo rotativo',
          RECIPROCATING: 'Alternativo',
          SCROLL: 'Scroll',
          CENTRIFUGAL: 'Centrífugo',
        },
        labels: {
          LOW: 'Bajo',
          MEDIUM: 'Medio',
          HIGH: 'Alto',
          EQUIPMENT: 'Equipo',
          SAVINGS: 'Ahorros',
          OPPORTUNITY: 'Oportunidad',
          LOSS: 'Pérdida',
          REPAIRED: 'Reparado',
          UNREPAIRED: 'No reparado',
          LESS_15_DAYS: 'Menos de 15 días',
          DAYS_16_TO_30: '15 a 30 días',
          DAYS_31_TO_60: '31 a 60 días',
          DAYS_61_TO_90: 'Más de 60 días',
          LESS_THAN_15_DAYS: 'Reparado en menos de 15 días',
          '16_TO_30_DAYS': 'Reparado en 16-30 días',
          '31_TO_60_DAYS': 'Reparado en 31-60 días',
          MORE_THAN_60_DAYS: 'Reparado en 61-90 días',
          AIR: 'Aire',
          METHANE: 'Metano (CH4)',
          CARBON_DIOXIDE: 'Dióxido de carbono (CO2)',
          HYDROGEN: 'Hidrógeno (H2)',
          HELIUM: 'Helio (He)',
          NITROGEN: 'Nitrógeno (N2)',
          REFRIGERANT: 'Refrigerante (HFCs)',
          ARGON: 'Argón (Ar)',
          OXYGEN: 'Oxígeno (O)',
          WATER: 'Agua (Vapor)',
          DEFAULT: 'Ninguno seleccionado',
          HOLE: 'Agujero',
          CRACK: 'Grieta',
          FITTING: 'Conector',
          FLANGE: 'Brida',
          'GLAND/SEAL': 'Gland/Seal',
          THREAD: 'Rosca',
          'TUBE/HOSE': 'Tubo/Manguera',
          COMPRESSED_AIR: 'Aire comprimido',
          VACUUM: 'Vacío',
          STEAM: 'Vapor',
          PROCESS_GAS: 'Gas de proceso',
          LIQUID: 'Líquido',
          ROTARY_SCREW: 'Tornillo rotativo',
          RECIPROCATING: 'Alternativo',
          SCROLL: 'Scroll',
          CENTRIFUGAL: 'Centrífugo',
          CONNECTOR: 'Conector',
          CONTROL_VALVE: 'Válvula de control',
          HOSE: 'Manguera',
          TUBE_FITTING: 'Conector de tubo',
          METER: 'Medidor',
          OPEN_ENDED_LINE: 'Línea abierta',
          PRESSURE_RELIEF_VALVE: 'Válvula de alivio de presión',
          PUMP_SEAL: 'Sello de bomba',
          REGULATOR: 'Regulador',
          VALVE: 'Válvula',
          LEVEL_CONTROLLER: 'Controlador de nivel',
          POSITIONER: 'Posicionador',
          PRESSURE_CONTROLLER: 'Controlador de presión',
          TRANSDUCER: 'Transductor',
          GENERIC_PNEUMATIC_INSTRUMENT: 'Instrumento neumático genérico',
          GENERIC_RECIPROCATING_COMPRESSOR: 'Compresor alternativo genérico',
          GENERIC_CENTRIFUGAL_COMPRESSOR: 'Compresor centrífugo genérico',
        },
        leakRecordPDF: 'Registro de evento de fuga • Exportado {{date}}',
        leakTag: 'Etiquetas de fuga inteligentes • Exportado {{date}}',
      },
      compressorFields: {
        labels: {
          COMPRESSOR_TAG: 'Etiqueta del compresor',
          COMPRESSOR_MAKE: 'Marca del compresor',
          COMPRESSOR_MODEL: 'Modelo del compresor',
          COMPRESSOR_TYPE: 'Tipo de compresor',
          NAMEPLATE_HP: 'Potencia nominal (HP)',
          NAMEPLATE_CAPACITY: 'Capacidad nominal (m³/h)',
          MOTOR_EFFICIENCY: 'Eficiencia del motor (%)',
          MOTOR_VOLTAGE: 'Voltaje del motor',
          FULL_LOAD_CURRENT: 'Corriente a plena carga (Amperios)',
          FULL_LOAD_INPUT_POWER: 'Potencia de entrada a plena carga (kW)',
          RATED_PRESSURE: 'Presión nominal (kPag)',
          PRESSURE_SETPOINT: 'Punto de ajuste de presión (kPag)',
          CONTROL: 'Control',
          TOTAL_AIR_STORAGE_SIZE: 'Tamaño total de almacenamiento de aire (l)',
          IS_ACTIVE: 'Compresor activo / apagado',
          OPERATION_TYPE: 'Trim / Carga base',
          PERCENT_LOAD: '% Carga',
          COMPRESSOR_SETPOINT_SEU: 'Punto de ajuste del compresor (kPag)',
          COMPRESSOR_FLOW_SEU: 'Flujo del compresor (m³/h)',
          SYSTEM_PRESSURE_SETPOINT: 'Punto de ajuste de presión del sistema (kPag)',
          SYSTEM_MEASURED_FLOW: 'Flujo medido del sistema (m³/h)',
          DATE_TIME_RUNNING_STATE: 'Fecha y hora del estado de funcionamiento',
          NOTES: 'Notas',
          NAMEPLATE: 'Placa de identificación del compresor',
          DATASHEET: 'Hoja de datos del compresor',
        },
        labelsImperial: {
          NAMEPLATE_CAPACITY: 'Capacidad nominal (cfm)',
          FULL_LOAD_INPUT_POWER: 'Potencia de entrada a plena carga (BTU/h)',
          RATED_PRESSURE: 'Presión nominal (psi)',
          PRESSURE_SETPOINT: 'Punto de ajuste de presión (psi)',
          TOTAL_AIR_STORAGE_SIZE: 'Tamaño total de almacenamiento de aire (gal)',
          COMPRESSOR_SETPOINT_SEU: 'Punto de ajuste del compresor (psi)',
          COMPRESSOR_FLOW_SEU: 'Flujo del compresor (cfm)',
          SYSTEM_PRESSURE_SETPOINT: 'Punto de ajuste de presión del sistema (psi)',
          SYSTEM_MEASURED_FLOW: 'Flujo medido del sistema (cfm)',
        },
      },
    },
    hooks: {
      useError: {
        message: 'Error de validación de la solicitud. Por favor, revise los campos del formulario',
      },
    },
    layouts: {
      dashboardLayout: {
        topBar: {
          registerADevice: 'Registrar un dispositivo',
          help: 'Ayuda',
          metric: 'Métrico',
          imperial: 'Imperial',
          downloadApk: 'Descargar APK',
          downloadOta: 'Descargar archivo',
        },
        otaDialog: {
          title: 'Descargar archivo',
          downloadButton: 'Descargar',
          serialNumberLabel: 'Número de serie del dispositivo',
          serialNumberRequired: 'Se requiere número de serie',
          fileDownloaded: 'Archivo {{fileName}} descargado con éxito',
          errorFetchingFile: 'Error al obtener el archivo. Por favor, inténtelo de nuevo más tarde',
          selectSerialNumber: 'Seleccione un número de serie de dispositivo',
        },
        banners: {
          leakRecordsList: {
            dialogOne: 'Estás en el plan Esencial.',
            dialogTwo: 'Haz clic ',
            dialogThree: 'aquí ',
            dialogFour: 'para comprar una nueva licencia de administrador o contacta con ',
            dialogFive: 'support@prosaris.ca',
          },
          serviceUnavailable:
            'Estamos experimentando dificultades técnicas. Nuestro servicio puede verse afectado. Disculpe las molestias.',
          tagsManagement: {
            dialogOne: 'Comprar más',
            dialogTwo: 'Etiquetas de fuga inteligentes PROSARIS',
            dialogThree: 'aquí！',
          },
        },
        mobileNav: {
          index: {
            menu: 'Menú',
            editProfile: 'Editar perfil',
            logout: 'Cerrar sesión',
          },
        },
        notifications: {
          notificationsList: {
            clearAll: 'Borrar todo',
            readAll: 'Marcar todo como leído',
            noNotifications: 'No hay notificaciones',
          },
        },
        index: {
          dashboard: 'Tablero',
          records: 'Registros',
          actions: 'Acciones',
          company: 'Empresa',
          tags: 'Etiquetas',
        },
        loggedInAs: 'Conectado como',
      },
    },
    utils: {
      displayAssignedRecords: {
        cannontDeterminByField: 'No se puede determinar la etiqueta por el campo {{field}}',
        cannontDeterminByLabel: 'No se puede determinar el campo por la etiqueta {{label}}',
        active: 'Activo',
        closed: 'Cerrado',
      },
    },
    views: {
      commonUsedWords: {
        dashboard: 'Tablero',
        records: 'Registros',
        actions: 'Acciones',
        company: 'Empresa',
        tags: 'Etiquetas',
        redirecting: 'Redirigiéndote...',
      },
      account: {
        index: {
          updatePassword: 'Contraseña actualizada con éxito.',
          accountDeletedSuccess: 'La cuenta {{user}} ha sido eliminada con éxito',
          accountDeletedError: 'No se puede eliminar la cuenta en este momento. Por favor, inténtelo de nuevo más tarde.',
          authenticationError: 'Error al autenticar al usuario',
          personalDetails: 'Detalles personales',
          currentPasswordIsRequired: 'Se requiere la contraseña actual',
          username: 'Nombre de usuario',
          changePassword: 'Cambiar contraseña',
          currentPassword: 'Contraseña actual',
          profilePicture: 'Foto de perfil',
          dangerZone: 'Zona de peligro',
          deleteAccountTitle: 'Eliminar cuenta',
          deleteAccountMessage: 'La cuenta ya no estará disponible y toda la información personal se eliminará permanentemente.',
          alertTitle: 'Por favor, confirme la eliminación de la cuenta',
          deleteDialog: {
            dialogOne: 'Atención:',
            dialogTwo: 'Eliminar su cuenta eliminará permanentemente toda su información personal de nuestra base de datos.',
            dialogThree: 'ESTA ACCIÓN NO SE PUEDE DESHACER.',
            dialogFour: 'Por favor, introduzca su contraseña para continuar.',
          },
        },
        toolBar: {
          cacheClearedSuccess: '¡Caché borrado con éxito!',
          editProfile: 'Editar perfil',
          clearCache: 'Borrar caché',
          alertTitle: 'Por favor, confirme el borrado del caché de la aplicación',
          deleteAll: 'Eliminar todo',
          accordionDialog: {
            dialogOne: 'Esto eliminará todas las configuraciones almacenadas para las siguientes páginas:',
            dialogTwo: 'Gráficos, filtros de gráficos, marco de tiempo',
            dialogThree: 'Filtros, Ordenar, Columnas, Vista previa',
            dialogFour: 'Filtros, columnas',
            dialogFive: 'Empresa seleccionada',
            dialogSix: 'Filtros, columnas',
          },
          deleteSelective: 'Eliminar selectivo',
          selectiveDialog: 'Por favor, seleccione las configuraciones almacenadas que desea eliminar:',
        },
        constants: {
          Dashboard: {
            TIMEFRAME: 'Marco de tiempo',
            CHART_FILTERS: 'Filtros de gráficos',
            CHARTS: 'Gráficos',
          },
          Records: {
            HIGHLIGHTED: 'Vista previa',
            COLUMNS: 'Columnas',
            FILTERS: 'Filtros',
            SORT_COLUMN: 'Ordenar',
          },
          Actions: {
            FILTERS: 'Filtros',
            COLUMNS: 'Columnas',
          },
          Company: {
            SELECTED: 'Empresa seleccionada',
          },
          Tags: {
            FILTERS: 'Filtros',
            COLUMNS: 'Columnas',
          },
        },
      },
      assignmentsList: {
        filters: {
          companyName: 'Empresa',
          'leakStatus.building': 'Unidad/Equipo',
          'leakStatus.facility': 'Instalación',
          'leakStatus.leakTag': 'Etiqueta de fuga #/Ref. de trabajo #',
        },
        tableBody: {
          noAssignmentsToDisplay: 'No hay asignaciones para mostrar',
        },
        filtersCommonWords: {
          recordsFilter: 'Filtro de registros',
          column: 'Columna',
          selectAColumn: 'Selecciona una columna...',
          addSearchTerm: 'Añadir un término de búsqueda...',
          filtersArentAvailable: 'Los filtros no están disponibles',
          value: 'Valor',
          exportCSV: 'Exportar CSV',
          show: 'Mostrar',
          loadMoreRecords: 'Cargar más registros',
          selectedAllRecords: 'Seleccionado todos los registros ({{totalNum}})',
          selectedFromRecords: 'Seleccionado {{selectedNum}} de {{totalNum}} registros',
        },
        invalidColumn: 'Columna no válida {{column}}',
        assignedActionRecords: 'Registros de acciones asignadas',
      },
      auth: {
        forgotPasswordView: {
          forgotYourPassword: '¿Olvidaste tu contraseña?',
          successMessage: 'Hemos enviado un correo electrónico de confirmación a',
        },
        authSuccess: {
          thanks: '¡Gracias!',
          backToLogin: 'Volver al inicio de sesión de Prosaris',
        },
        loginView: {
          forgotPass: '¿Olvidó la contraseña?',
          createAccount: 'Crear una cuenta',
          passwordIsRequired: 'Se requiere contraseña',
          resetPassword: 'Restablecer contraseña',
          contactSupport: 'Por favor, contacte a support@prosaris.ca',
          incorrectEmailOrPassword: 'Correo electrónico o contraseña incorrectos',
          resetPassDialog: {
            dialogOne: 'Debe restablecer su contraseña antes de continuar.',
            dialogTwo: 'Se le ha enviado un correo electrónico para restablecer la contraseña con instrucciones.',
          },
          ok: 'OK',
        },
        resetPasswordView: {
          passwordSuccessFullyReset: 'La contraseña se ha restablecido con éxito',
          enterNewPassword: 'Introduce tu nueva contraseña a continuación.',
          passwordChanged: 'Tu contraseña ha sido cambiada.',
        },
        createCompanyView: {
          createCompany: 'Crear una empresa',
          companyName: 'Nombre de la empresa',
          successCreatingCompany: 'Su empresa ha sido creada.',
        },
      },
      calculateLeak: {
        index: {
          loadingLeakError: 'Error inesperado al cargar los datos de la fuga',
          calculatingError: 'Error inesperado durante el cálculo',
          dbMeasured: 'Db medido',
          calculate: 'Calcular',
          calculating: 'Calculando...',
          outflowCoefficient: 'Coeficiente de salida',
          kwhPerYear: 'Kwh/año',
        },
      },
      companies: {
        commonUsedWords: {
          assigendAdminLicenses: 'Licencias de administrador asignadas',
          company: 'Empresa',
          totalAccounts: 'Cuentas totales',
          email: 'Correo electrónico',
          name: 'Nombre',
          loadCompanyUsersError: 'Error al cargar usuarios de la empresa. Por favor, inténtelo de nuevo más tarde',
          activated: 'Activado',
          deactivated: 'Desactivado',
          activating: 'Activando',
          deactivating: 'Desactivando',
          deactivate: 'Desactivar',
          activate: 'Activar',
          set: 'Establecer',
          youAreGoingTo: 'Vas a',
          areYouSure: '¿Estás seguro?',
          companyNameIsRequired: 'Se requiere el nombre de la empresa',
          editCompany: 'Editar empresa',
          createCompany: 'Crear empresa',
          viewHistory: 'Ver historial',
          onEssentialPlan: 'Estás en un plan esencial. Por favor, contacta con support@prosaris.ca',
          exportCSV: 'Exportar CSV',
        },
        accounts: {
          accountDialog: {
            companyIsRequired: 'Se requiere empresa',
            roleIsRequired: 'Se requiere rol',
            createAccountSuccessMessage: 'Cuenta creada con éxito',
            emailTakenErrorMessage: 'Una cuenta con este correo electrónico ya existe en el sistema',
            errorWhileCreatingAccountMessage: 'Error al crear una cuenta. Por favor, inténtelo de nuevo más tarde.',
            changeAccountSuccessMessage: 'Cuenta actualizada con éxito',
            errorWhileChangingAccountMessage: 'Error al actualizar la cuenta. Por favor, inténtelo de nuevo.',
            editAccount: 'Editar cuenta',
            createAccount: 'Crear cuenta',
            role: 'Rol',
          },
          changeOwner: {
            changeOwnerMessage: '¿También desea cambiar el propietario de los registros de fugas relacionados con esta cuenta?',
            no: 'No, mantener la cuenta actual en su lugar',
          },
          contentCell: {
            loadAccountRolesError: 'Error al cargar la información de los roles de la cuenta. Por favor, inténtelo de nuevo más tarde',
            changeRoleSuccessMessage: 'La cuenta {{email}} tiene un nuevo rol',
            changeRoleErrorMessage: 'Error al actualizar el rol para la cuenta {{email}}. Por favor, inténtelo de nuevo más tarde',
            editUser: 'Editar usuario',
            adminSetPassword: 'Administrador establece contraseña',
            resetPassword: 'Restablecer contraseña',
            user: 'Usuario',
            deleteUser: 'Eliminar usuario',
          },
          index: {
            noUsersToDisplay: 'No hay usuarios para mostrar',
            changeStatusSuccessMessage: 'Usuario {{isActive}} con éxito',
            changeStatusErrorMessage: 'Error al {{isActive}} usuario. Por favor, inténtelo de nuevo',
            changeOwnerSuccessMessage: 'Los registros de fugas fueron transferidos a un nuevo propietario',
            changeOwnerErrorMessage:
              'No se pueden transferir los registros a un nuevo propietario. Por favor, inténtelo de nuevo más tarde',
            deleteAccountMutationSuccessMessage: 'La cuenta {{email}} ha sido eliminada con éxito',
            deleteAccountMutationErrorMessage:
              'No se puede eliminar la cuenta {{email}} en este momento. Por favor, inténtelo de nuevo más tarde',
            resetAccountPassWordSuccessMessage: 'Correo de restablecimiento de contraseña enviado con éxito',
            users: 'usuarios',
            noneAvailable: 'Ninguno disponible',
            of: 'de',
            contactSupport:
              'Por favor, contacte a support@prosaris.ca para obtener información adicional sobre las licencias de administrador de la empresa',
            findAccount: 'Buscar cuenta',
            placeholder: 'Escriba el nombre o el correo electrónico...',
            tooltip: 'Necesita al menos una licencia de administrador de la empresa antes de poder invitar a usuarios',
            invite: 'Invitar',
            alertDialog: {
              messageOne: 'Por favor, confirme la solicitud de restablecimiento de contraseña del usuario',
              messageTwo: 'Por favor, confirme el cambio de estado de la cuenta',
              messageThree: 'Por favor, confirme la eliminación de la cuenta',
            },
            sendAPasswordResetEmail: 'Va a enviar un correo de restablecimiento de contraseña para la siguiente cuenta:',
            confirmMessage: '¿Desea continuar?',
            followingAccount: 'siguiente cuenta:',
            delete: 'ELIMINAR',
            userCreatedDialogTitle: 'Enlace de restablecimiento de contraseña de usuario enviado con éxito',
            userCreatedDialogDescription:
              'Se ha enviado un enlace de restablecimiento de contraseña a la dirección de correo electrónico del usuario. También puede copiar el enlace a continuación.',
          },
          adminSetPassword: {
            changedUserPasswordSuccessMessage: 'Contraseña de la cuenta actualizada con éxito',
            changedUserPasswordErrorMessage: 'Error al actualizar la cuenta. Por favor, inténtelo de nuevo.',
            setNewPassword: 'Establecer nueva contraseña',
          },
          setPassword: {
            automatic: 'Generar automáticamente una contraseña fuerte',
            manual: 'Crear contraseña manualmente',
            copyLinkInstruction: 'Podrás ver y copiar la contraseña en el siguiente paso',
          },
          userCreatedDialog: {
            title: 'Usuario creado con éxito',
            description:
              'Su nuevo usuario ahora puede comenzar a usar el panel de Prosaris. El usuario también recibirá un enlace por correo electrónico para restablecer su contraseña.',
            passwordCopied: 'Contraseña copiada al portapapeles',
            passwordResetLinkCopied: 'Enlace de restablecimiento de contraseña copiado al portapapeles',
            copyPassword: 'Copiar contraseña',
            copyPasswordResetLink: 'Copiar enlace de restablecimiento de contraseña',
          },
          useRoleOptions: {
            notAvailable: '(no disponible)',
            adminSeatTaken: '(el asiento de administrador está ocupado)',
            allSeatsAreTaken: '(todos los {{numSeats}} asientos están ocupados)',
            adminRolesTitle: 'Roles de administrador',
            roles: {
              superAdmin: 'Administrador de Prosaris',
              companyAdmin: 'Empresa (Administrador)',
              superCompanyAdmin: 'Super Empresa (Administrador)',
              companyRead: 'Empresa (Solo lectura)',
              superAdminRead: 'Administrador de Prosaris (Solo lectura)',
              integrator: 'Integrador',
              auditor: 'Auditor',
              user: 'Usuario',
              essential: 'Prosaris Esencial',
            },
            rolesDescriptions: {
              superAdmin: 'Permitido tener control total sobre múltiples empresas.',
              companyAdmin: 'Permitido ver, editar y asignar registros de la empresa.',
              superCompanyAdmin: 'Permitido tener control total sobre los registros y usuarios de la empresa.',
              companyRead: 'Permitido ver los registros de la empresa en modo de solo lectura.',
              superAdminRead: 'Permitido tener acceso de solo lectura a todas las empresas.',
              integrator: 'Permite integraciones de terceros para gestionar empresas y cuentas.',
              auditor: 'Para permitir la auditoría de registros de fugas.',
              user: 'Permitido crear/actualizar/eliminar sus propios registros.',
              essential: 'Un rol con funcionalidad limitada.',
            },
            nonAdminRolesTitle: 'Roles no administrativos',
          },
        },
        audits: {
          index: {
            loadAuditsErrorMessage: 'Error al cargar las auditorías. Por favor, inténtelo de nuevo más tarde',
            audits: 'auditorías',
            noAuditsToDisplay: 'No hay auditorías para mostrar',
            findByAuditorEmail: 'Buscar por correo electrónico del auditor',
            placeholder: 'Escriba el correo electrónico del auditor...',
          },
        },
        companyTabs: {
          facility: {
            index: {
              facilityNameIsRequired: 'Se requiere el nombre de la instalación',
              hoursYearlyIsRequired: 'Se requieren horas totales/año',
              totalHoursYearly: 'Las horas totales/año no pueden exceder de 8760',
              invalidPhoneNumber: 'Número de teléfono no válido',
              createErrorMessage: 'Horas x Turnos no puede exceder de 24 horas',
              loadFacilityErrorMessage: 'Error al cargar la instalación. Por favor, inténtelo de nuevo más tarde',
              createFacilitySuccessMessage: 'Instalación creada con éxito',
              createFacilityErrorMessage: 'Error al crear una instalación. Por favor, inténtelo de nuevo más tarde.',
              changeFacilitySuccessMessage: 'Instalación actualizada con éxito',
              changeFacilityErrorMessage: 'Error al actualizar la instalación. Por favor, inténtelo de nuevo.',
              facilityName: 'Nombre de la instalación',
              abbreviation: 'Abreviatura',
              address: 'Dirección',
              enterALocation: 'Ingresar una ubicación',
              tooltip: 'para actividades de gestión de energía, también aprobador de informes',
              energyMgtOwner: 'Propietario de Gestión Energética',
              title: 'Título',
              phone: 'Teléfono',
              tooltipTwo: 'para renovaciones de soluciones y problemas generales de la cuenta',
              accountOwner: 'Propietario de la cuenta',
              manufacturingArea: 'Área de producción',
              size: 'Tamaño',
              unit: 'Unidad',
              hoursOfOperation: 'Horas de operación',
              auditFrequency: 'Frecuencia de auditoría',
            },
            operationHours: {
              daysPerWeek: 'Días/Semana',
              shiftsPerDay: 'Turnos/Día',
              hoursPerShift: 'Horas/Turno',
              weeksPerYear: '52 semanas/año',
              totalHoursYearly: 'Total = Horas/Año',
            },
            constants: {
              constant: 'Constante',
              monthly: 'Mensual',
              threeMonth: '3 Meses',
              fourMonth: '4 Meses',
              sixMonth: '6 Meses',
              nineMonth: '9 Meses',
              annual: 'Anual',
              sqft: 'Pies cuadrados [SQFT]',
              sqm: 'Metros cuadrados [SQM]',
            },
          },
          index: {
            users: 'Usuarios',
            devices: 'Dispositivos',
            facility: 'Instalación',
            energy: 'Energía',
            seu: 'SEUs - Comp.',
            enpi: 'EnPIs/EnBs',
            routes: 'Rutas',
            audit: 'Auditorías',
            comingSoon: 'Próximamente...',
          },
        },
        enpis: {
          index: {
            loadEnpisErrorMessage: 'Error al cargar los EnPIs. Por favor, inténtelo de nuevo más tarde',
            createEnpisSuccessMessage: 'EnPIs creados con éxito',
            createEnpisErrorMessage: 'Error al crear los EnPIs. Por favor, inténtelo de nuevo más tarde.',
            changeEnpisSuccessMessage: 'EnPIs actualizados con éxito',
            changeEnpisErrorMessage: 'Error al actualizar los EnPIs. Por favor, inténtelo de nuevo.',
            showHistory: 'Mostrar historial',
          },
          changeHistory: {
            bulkOperation: 'como una operación en masa',
            details: 'detalles',
            created: 'creado',
            updated: 'actualizado',
            componentTypes: {
              enpis: 'EnPIs',
            },
            history: 'Historial',
            noHistory: 'Sin historial',
          },
        },
        compressors: {
          compressorDialog: {
            plateError: 'Error al cargar la placa de identificación. Por favor, inténtelo de nuevo más tarde.',
            dataSheetError: 'Error al cargar la hoja de datos. Por favor, inténtelo de nuevo más tarde.',
            createSuccess: 'Compresor {{tag}} creado',
            createError: 'Error al crear el compresor. Por favor, inténtelo de nuevo más tarde.',
            createCompressor: 'Crear compresor',
          },
          compressorFile: {
            tip: 'Añadir archivo',
            removeFile: 'Eliminar archivo',
            removeImage: 'Eliminar imagen',
            title: 'Por favor, confirme la eliminación del compresor {{prop}}',
            proceed: '¿Quiere continuar?',
          },
          compressorForm: {
            compTagIsReq: 'La etiqueta del compresor es obligatoria',
            hpMustBeNum: 'La potencia nominal debe ser un número',
            capMustBeNum: 'La capacidad nominal debe ser un número',
            motorEffMustBeNum: 'La eficiencia del motor debe ser un número',
            motorVoltMustBeNum: 'El voltaje del motor debe ser un número',
            loadMustBeNum: 'La corriente a plena carga debe ser un número',
            loadInputMustBeNum: 'La potencia de entrada a plena carga debe ser un número',
            pressureMustBeNum: 'La presión nominal debe ser un número',
            setpointMustBeNum: 'El punto de ajuste de presión debe ser un número',
            airMustBeNum: 'El tamaño total de almacenamiento de aire debe ser un número',
            addImage: 'Agregar imagen',
            addFile: 'Agregar archivo',
          },
          constants: {
            compressorTypes: {
              ROTARY_SCREW: 'Tornillo rotativo',
              RECIPROCATING: 'Alternativo',
              CENTRIFUGAL: 'Centrífugo',
            },
            rotaryScrew: {
              loadUnloadOilFree: 'Carga/Descarga – Sin aceite',
              loadUnload1Gal: 'Carga/Descarga (1 gal/CFM)',
              loadUnload3Gal: 'Carga/Descarga (3 gal/CFM)',
              loadUnload5Gal: 'Carga/Descarga (5 gal/CFM)',
              loadUnload10Gal: 'Carga/Descarga (10 gal/CFM)',
              ivmWithBlowdown: 'Modulación de la válvula de entrada (sin descarga)',
              ivmWithoutBlowdown: 'Modulación de la válvula de entrada (con descarga)',
              variableDisplacement: 'Desplazamiento variable',
              vsdWithUnloading: 'Variador de frecuencia con descarga',
              vsdWithStopping: 'Variador de frecuencia con parada',
            },
            reciprocating: {
              reciprocatingOnOffControl: 'Control de encendido/apagado',
              reciprocatingLoadUnload: 'Carga/Descarga',
            },
            centrifugal: {
              centrifugalInletIgv: 'IBV con descarga',
              centrifugalInletIbv: 'IGV con descarga',
            },
          },
          contentCell: {
            min: 'El porcentaje de carga debe ser al menos 0',
            max: 'El porcentaje de carga no puede ser superior a 100',
            typeError: 'La carga debe ser un porcentaje válido',
            setpointMustBeNum: 'El punto de ajuste del compresor debe ser un número',
            flowMustBeNum: 'El flujo del compresor debe ser un número',
            pressureSetpointMustBeNum: 'El punto de ajuste de presión del sistema debe ser un número',
            measuredFlowMustBeNum: 'El flujo medido del sistema debe ser un número',
            dateTime: 'Selecciona fecha y hora',
            active: 'ACTIVO',
            off: 'APAGADO',
            trim: 'Ajuste',
            baseLoad: 'Carga base',
          },
          index: {
            error: 'Error al cargar los compresores. Por favor, inténtelo de nuevo más tarde',
            nameplateError: 'Error al cargar la placa de identificación. Por favor, inténtelo de nuevo más tarde',
            dataSheetError: 'Error al cargar la hoja de datos. Por favor, inténtelo de nuevo más tarde',
            changeCompSuccess: 'Compresor actualizado con éxito',
            changeCompError: 'Error al actualizar el compresor. Por favor, inténtelo de nuevo.',
            deleteCompSuccess: 'Compresor eliminado con éxito',
            deleteCompError: 'Error al eliminar el compresor. Por favor, inténtelo de nuevo.',
            createCompNameplateSuccess: 'Placa de identificación del compresor cargada con éxito',
            createCompNameplateError: 'Error al cargar la placa de identificación. Por favor, inténtelo de nuevo más tarde.',
            deleteCompNameplateSuccess: 'Placa de identificación del compresor eliminada con éxito',
            deleteCompNameplateError: 'Error al eliminar la placa de identificación del compresor. Por favor, inténtelo de nuevo.',
            createCompDataSheetSuccess: 'Hoja de datos del compresor cargada con éxito',
            createCompDataSheetError: 'Error al cargar la hoja de datos. Por favor, inténtelo de nuevo más tarde.',
            deleteCompDataSheetSuccess: 'Hoja de datos del compresor eliminada con éxito',
            deleteCompDataSheetError: 'Error al eliminar la hoja de datos del compresor. Por favor, inténtelo de nuevo.',
            changeCompanyCompSuccess: 'Datos del sistema actualizados con éxito',
            changeCompanyCompError: 'Error al actualizar los datos del sistema. Por favor, inténtelo de nuevo.',
            compDesignData: 'Datos de diseño del compresor',
            comp: 'Compresores:',
            addComp: 'Agregar compresor',
            editCompPermission: 'Necesitas una licencia de administrador para editar el compresor. Por favor, contacta',
            selectOption: 'Selecciona una opción',
            undoChanges: 'Deshacer cambios',
            selectComp: 'Selecciona un compresor de arriba.',
            noCompsFound: 'No se encontraron compresores.',
            compRunningState: 'Estado de funcionamiento del compresor',
            noCompsFoundAddComp: 'No se encontraron compresores. Agrega un compresor desde la sección de arriba.',
            title: 'Por favor, confirme la eliminación del compresor',
            areYouSure: '¿Estás seguro de que quieres',
            compressor: 'compresor',
            delete: 'eliminar',
          },
        },
        devices: {
          contentCell: {
            device: 'Dispositivo',
            usedByMultipleCompanies: 'es utilizado por varias empresas',
          },
          index: {
            noDevicesToDisplay: 'No hay dispositivos para mostrar',
            loadingDevicesErrorMessage: 'Error al cargar los dispositivos de la empresa. Por favor, inténtelo de nuevo más tarde',
            changeStatusSuccessMessage: 'Dispositivo {{isActive}} con éxito',
            changeStatusErrorMessage: 'Error al {{isActive}} dispositivo. Por favor, inténtelo de nuevo',
            devices: 'dispositivos',
            findDevice: 'Buscar dispositivo',
            placeholder: 'Escriba el ID del dispositivo...',
            activatedOn: 'Activado el',
            title: 'Por favor, confirme el cambio de estado del dispositivo',
          },
        },
        company: {
          deleteCompanySuccessMessage: '{{companyName}} ha sido eliminado con éxito',
          deleteCompanyErrorMessage: 'Error al eliminar {{companyName}}. Por favor, inténtelo de nuevo más tarde.',
          needAdminLicense: 'Necesita una licencia de administrador para acceder a esta función. Por favor, contacte a support@prosaris.ca',
          deleteChildCompanyFirst:
            'No se puede eliminar la empresa con filiales o cuentas. Por favor, elimine primero todas las filiales y cuentas.',
          editSettings: 'Editar configuración',
          company: 'Empresa',
          createASubCompany: 'Crear una filial para {{companyName}}',
          deleteCompany: 'Eliminar empresa',
          cannotCreateChildCompanyDialog: {
            messageOne:
              'Ha alcanzado el número máximo de empresas que pueden ser creadas por sus cuentas de Super Company Admin. Por favor, contacte a ',
            messageTwo: 'para más detalles.',
          },
          deleteCompanyDialog: {
            title: 'Por favor, confirme la eliminación de la empresa',
            delete: 'Eliminar',
            attention: 'Atención: ',
            deleting: 'Eliminando',
            permanentlyDeleted: 'lo eliminará permanentemente de nuestra base de datos.',
            cannotBeUndone: 'ESTA ACCIÓN NO SE PUEDE DESHACER.',
          },
        },
        companyDialog: {
          industryIsRequired: 'Se requiere industria',
          createLogoErroerMessage: 'Error al subir la imagen. Por favor, inténtelo de nuevo más tarde.',
          deleteLogoErrorMessage: 'Error al eliminar la imagen. Por favor, inténtelo de nuevo más tarde.',
          createCompanySuccessMessage: 'Empresa {{name}} creada',
          createCompanyErrorMessage: 'Error al crear la empresa. Por favor, inténtelo de nuevo más tarde.',
          changeCompanySuccessMessage: 'Empresa {{name}} actualizada',
          changeCompanyErrorMessage: 'Error al cambiar el parámetro de la empresa. Por favor, inténtelo de nuevo más tarde.',
          language: 'Idioma',
          industry: 'Industria',
          country: 'País',
          currency: 'Moneda',
        },
        companyLogo: {
          setImageErrorMessage: 'El tamaño del archivo debe ser inferior a 1 MB',
          logo: 'Logotipo',
          add: 'Añadir',
          removeLogo: 'Eliminar logotipo',
          addLogo: 'Añadir logotipo',
        },
        index: {
          loadCompaniesErrorMessage: 'Error al cargar la lista de empresas. Por favor, inténtelo de nuevo más tarde',
          changeCompaniesSectionWidth: 'Cambiar el ancho de la sección de empresas',
        },
        sidebar: {
          filterCompanies: 'Filtrar empresas',
          placeholder: 'Introduzca el nombre de la empresa',
          myCompany: 'Mi empresa',
          childCompanies: 'Empresas filiales',
        },
        routes: {
          index: {
            noFacility: 'No tiene ninguna instalación definida',
            createAFacility: 'Cree una instalación para comenzar a planificar rutas',
            goToCreateAFacility: 'Ir a crear una instalación',
            createAFacilityTooltip: 'Debe crear una instalación antes de poder comenzar a planificar rutas.',
            save: 'Guardar',
            reset: 'Restablecer',
            routePlans: 'Planes de ruta',
            loading: 'Cargando...',
            noRoutesAdded: 'No se han agregado rutas.',
            buildARouteTooltip: 'Construya una ruta usando el Constructor de Rutas.',
            createFloorplanSuccess: '¡Éxito! ¡Plano subido!',
            createRouteSuccess: '¡Éxito! ¡Ruta creada!',
          },
          routeBuilder: {
            title: 'Constructor de Rutas',
            missingFields: 'Faltan campos en el archivo de Excel',
            manuallyBuildARoute: 'Construir manualmente un nuevo plan de ruta',
            manualInstructions: 'Puede construir rutas manualmente o subiendo un archivo Excel.',
            facility: 'Instalación',
            route: 'Ruta',
            enterRoute: 'Ingresar ruta',
            addFloorplan: 'Agregar plano',
            floorplanInstructionsTooltip:
              'Agregue una imagen de un plano para ayudarle a construir rutas. La ruta se superpondrá en el plano cuando se guarde.',
            change: 'Cambiar',
            location: 'Ubicación',
            enterLocation: 'Ingresar ubicación',
            equipment: 'Equipo',
            enterEquipment: 'Ingresar equipo',
            criticality: 'Criticidad',
            enterCriticality: 'Ingresar criticidad',
            description: 'Descripción',
            enterDescription: 'Ingresar descripción',
            addEquipment: 'Agregar equipo',
            addRoute: 'Agregar ruta',
            downloadTemplate: 'Descargar plantilla',
          },
          excelReader: {
            loadFromFile: 'Cargar desde archivo',
            instructionsTooltip:
              'Puede construir rutas subiendo un archivo Excel donde cada hoja representa una ubicación diferente en una instalación. Cada ubicación puede tener múltiples rutas.',
          },
          graph: {
            controls: {
              addFloorplan: 'Agregar plano',
              toggleLabels: 'Alternar etiquetas',
              addWaypoint: 'Agregar punto de ruta',
              addEquipment: 'Agregar equipo',
              swapEquipment: 'Intercambiar equipo',
              showLeakStats: 'Mostrar estadísticas de fugas',
              save: 'Guardar',
              delete: 'Eliminar',
            },
            routeStats: {
              title: 'Estadísticas de Ruta',
              totalDollarLossPerYear: 'Pérdida total anual en dólares:',
              totalKwhLossPerYear: 'Pérdida total anual en Kwh:',
              totalLeakCount: 'Total de fugas:',
              totalActiveOpen: 'Total activo abierto:',
              totalActiveClosed: 'Total activo cerrado:',
              noStatsAvailable: 'No hay estadísticas disponibles:',
              close: 'Cerrar',
            },
            nodeDetails: {
              edit: 'Editar',
              name: 'Nombre',
              description: 'Descripción',
              criticality: 'Criticidad',
              equipmentInformation: 'Información del equipo',
              thisIsAWaypoint: 'Este es un punto de ruta',
              activeOpenLeakCount: 'Recuento de fugas activas abiertas:',
              noActiveLeaksDetected: 'No se detectaron fugas activas',
              close: 'Cerrar',
              nothingToSeeHere: 'Nada que ver aquí',
              seeLeakRecords: 'Ver registros de fugas',
            },
            swapEquipment: {
              title: 'Intercambiar equipo',
              selectEquipment: 'Seleccione el equipo para intercambiar:',
              selectFirst: 'Seleccione el primer equipo',
              selectSecond: 'Seleccione el segundo equipo',
              swap: 'Intercambiar',
              cancel: 'Cancelar',
            },
            addNode: {
              title: 'Agregar',
              waypoint: 'Punto de ruta',
              equipment: 'Equipo',
              afterWhich: '¿Después de qué equipo?',
              selectPreceding: 'Seleccione el equipo precedente:',
              selectEquipment: 'Seleccionar equipo',
              name: 'Nombre',
              description: 'Descripción',
              criticality: 'Criticidad',
              add: 'Agregar',
              cancel: 'Cancelar',
            },
            save: 'Guardar',
            delete: 'Eliminar',
            successRouteUpdated: 'Ruta actualizada con éxito',
            errorRouteUpdated: 'Error al actualizar la ruta. Intente de nuevo más tarde.',
            successRouteDeleted: 'Ruta eliminada con éxito',
            errorRouteDeleted: 'Error al eliminar la ruta. Intente de nuevo más tarde.',
            successFloorplanUpdated: 'Plano actualizado con éxito',
            errorFloorplanUpdated: 'Error al actualizar el plano. Intente de nuevo más tarde.',
          },
        },
      },
      companySettings: {
        settings: {
          adminLicenses: {
            aal: 'Licencias de administrador asignadas',
            of: 'de',
            edit: 'Editar',
          },
          essentialMode: {
            disable: 'deshabilitar',
            enable: 'habilitar',
            essential: 'Modo esencial:',
            enabled: 'HABILITADO',
            disabled: 'DESHABILITADO',
            moveCompany: 'Mover la empresa {{company}} al modo esencial',
            affectedCompanies: 'Número de empresas afectadas',
            affectedAccounts: 'Número de cuentas afectadas',
            archivedLeak: 'Número de registros de eventos de fuga archivados',
            applyingChanges:
              'Al aplicar estos cambios, las cuentas obtendrán el rol "Esencial". Los usuarios podrán ver {{quota}} registros de eventos de fugas más recientes en un panel de control y una aplicación móvil. Otros registros serán archivados.',
            essentialLabel: 'Cambiar el modo esencial para subempresas',
            turnOffEssential: 'Desactivar el modo esencial para la empresa {{company}}',
            byApplyingChanges:
              'Al aplicar estos cambios, todas las cuentas obtendrán el rol de "Usuario". Los usuarios podrán ver todos sus registros. Todos los registros serán desarchivados.',
          },
          index: 'Configuración',
        },
        index: {
          loadCompanyError: 'No se puede cargar la información de la empresa. Por favor, inténtelo de nuevo más tarde',
          companySlug: 'Alias de la empresa',
          notSelected: 'No seleccionado',
          country: 'País',
          accounts: 'Cuentas',
          currency: 'Moneda',
          adminLicenses: 'Licencias de administrador',
          totalCustomer: 'Total de cuentas de clientes en el árbol',
        },
      },
      device: {
        registerDeviceView: {
          registerDevice: 'Registre su dispositivo',
          toolTip: 'Por favor, localice el número de serie en su tarjeta de registro o en la caja.',
          serialNumberIsRequired: 'Se requiere el número de serie',
          oops: '¡Ups!',
          deviceNotFound: 'Número de serie del dispositivo no encontrado',
          deviceFoundNdAssociatedCompany: 'Su dispositivo fue encontrado y está asociado con una empresa.',
          deviceFound: 'Su dispositivo fue encontrado.',
          goToLogin: 'Ir a iniciar sesión',
          contactSupport: 'Por favor, contacte con el soporte',
        },
        deviceErrorCodes: {
          'B13:001': 'Número de serie del dispositivo no encontrado',
          'B13:002': 'Dispositivo ya activado',
          'B13:003': 'Correo electrónico de administrador no enviado',
          'B13:004': 'Empresa no encontrada',
          'B13:005': 'Empresa no válida',
          'B13:006': 'Tipo inválido',
        },
        registerAccountView: {
          registerAccount: 'Crear una cuenta de Prosaris',
          termsMustBeAccepted: 'Se deben aceptar los términos y condiciones.',
          saleType012Success: 'Puedes iniciar sesión para comenzar a usar tu dispositivo.',
          saleType3Success: 'Has sido agregado como usuario básico. Los administradores de tu empresa han sido notificados.',
          saleType4Success: {
            companyPlatformFound: 'Licencia de plataforma empresarial encontrada.',
            dialogOne: 'Este dispositivo está asociado con una nueva licencia de plataforma empresarial.',
            dialogTwo: 'Your company may already exist in our system.: Es posible que tu empresa ya exista en nuestro sistema.',
            dialogThree: 'If so, please contact your administrator.: Si es así, por favor contacta a tu administrador.',
          },
          termsAndConditions: {
            dialogOne: 'IAcepto los',
            dialogTwo: 'Términos de Servicio',
            dialogThree:
              'de Prosaris y confirmo que he tenido suficiente tiempo para leerlos y entender que contienen términos importantes sobre mi uso del software de Prosaris, como la limitación de la responsabilidad de Prosaris y cómo se manejarán mis datos.',
          },
          acceptTerms: 'Aceptar términos',
          registerAccountErrorCodes: {
            'B02:001': 'Código de registro inválido',
            'B02:002': 'Empresa de usuario inválida',
            'B02:003': 'Rol de usuario inválido',
            'B02:004': 'El correo ya está en uso',
            'B02:005': 'El correo ya está en uso',
            'B02:006': 'No se puede restablecer la contraseña para su cuenta',
            'B02:007': 'Empresa de usuario inválida',
            'B02:008': 'Falta foto de perfil',
            'B02:009': 'Se requiere restablecer la cuenta. El proceso ha comenzado',
            'B02:010': 'La cuenta ha sido desactivada',
            'B02:011': 'Formato de foto de perfil inválido',
            'B02:012': 'Dispositivo ya registrado en una empresa',
            'B02:013': 'SNúmero de serie indefinido',
            'B02:014': 'Tipo inválido',
            'B02:015': 'Enlace para restablecer contraseña ha expirado',
            'B02:016': 'No se puede crear una cuenta para esta empresa',
            'B02:017': 'Rol inválido',
            'B02:018': 'Rol inválido',
            'B02:019': 'Empresa de usuario inválida',
            'B02:020': 'Rol de usuario inválido',
            'B02:021': 'El usuario no pertenece a esta empresa',
          },
        },
        devicesView: {
          serialNumbersIsReq: 'Se requieren números de serie',
          failedToCopy: 'Números de serie fallidos copiados al portapapeles',
          invalidFormat: 'Uno o más dispositivos tienen un formato no válido. Por favor, verifique e intente de nuevo.',
          devices: 'Registre sus dispositivos',
          deviceSerialNumber: 'Número(s) de serie del dispositivo',
          note: 'Nota: Ingrese cada número de serie en una nueva línea o separado por una coma.',
          title: 'Ubique el número de serie en su tarjeta de registro o en la caja.',
          register: 'Registrar',
          deviceRegistrationStatus: 'Estado de registro del dispositivo',
          success: 'Éxito',
          noDeviceFound: 'No se encontraron dispositivos.',
          failed: 'Fallido',
          copyFailed: 'Copiar los números de serie fallidos al portapapeles',
        },
      },
      errors: {
        notFoundView: {
          title: 'La página que buscas no está aquí',
          description: 'O bien has intentado una ruta dudosa o has llegado aquí por error. Sea lo que sea, intenta usar la navegación.',
        },
        accountNotFound: {
          title: 'Cuenta no encontrada',
          description:
            '¡Inténtalo de nuevo! Si el problema persiste, es posible que tu cuenta esté inactiva o que no haya sido inicializada por el administrador. Por favor, contacta con tu administrador.',
          logout: 'Cerrar sesión',
        },
      },
      integration: {
        index: {
          loggingInError: 'Error al iniciar sesión en Prosaris.',
        },
      },
      leakRecordDetails: {
        assignedActions: {
          actionImages: 'No hay imágenes disponibles',
          activeActionRecord: {
            updateAssignmentSuccess: 'La acción asignada ha sido actualizada.',
            updateAssignmentError: 'Error al actualizar la acción asignada.',
            deleteImageSuccess: 'La imagen asignada ha sido eliminada.',
            deleteImageError: 'Error al eliminar la imagen de la acción asignada.',
            confirmMarkAction: '¿Realmente quiere marcar esta acción como completa?',
            confirmDeleteImage: '¿Realmente quiere eliminar esta imagen?',
          },
          index: 'Error al cargar las acciones asignadas para un registro.',
        },
        audio: {
          noAudioAvailable: 'No hay datos de audio disponibles',
          playPause: 'Reproducir / Pausar',
          generateRegion: 'Generar Región',
          removeLastRegion: 'Eliminar Última Región',
          generateMarker: 'Generar Marcador',
          removeLastMarker: 'Eliminar Último Marcador',
          toggleTimeline: 'Alternar Línea de Tiempo',
          download: 'Descargar',
        },
        audit: {
          updateAuditSuccess: 'Auditoría actualizada con éxito',
          updateAuditError: 'Error al guardar el comentario. Por favor, inténtelo de nuevo más tarde.',
          createAuditSuccess: 'Auditoría creada con éxito',
          createAuditError: 'Error al crear la auditoría. Por favor, inténtelo de nuevo más tarde.',
          deleteAuditSuccess: 'La auditoría ha sido eliminada con éxito',
          deleteAuditError: 'No se pudo eliminar la auditoría. Por favor, inténtelo de nuevo más tarde.',
          noComment: 'Sin comentarios',
          reviewComments: 'Revisar comentarios',
          reviewedBy: 'Revisado por:',
          auditDate: 'Fecha de la auditoría:',
          deleteAudit: 'Eliminar auditoría',
          reviewedNoComments: 'Revisado sin comentarios',
          auditComments: 'Comentarios de la auditoría',
          saveComment: 'Guardar comentario',
          discardChanges: 'Descartar cambios',
        },
        changeHistory: {
          created: 'creado',
          updated: 'actualizado',
          deleted: 'eliminado',
          transferred: 'transferido',
          leak: 'Fuga',
          images: 'Imagen',
          audit: 'Auditoría',
          audio: 'Audio',
          history: 'Historial',
          noHistory: 'Sin historial',
          bulk: 'como una operación en bloque',
          details: 'detalles',
          unset: 'sin definir',
        },
        fft: {
          fft: 'FFT (Transformada Rápida de Fourier)',
          fftAvg: 'Promedio FFT',
          notApplicable: 'No aplicable',
          chartLayers: 'Capas del gráfico',
          avg: 'Promedio',
          all: 'Todo',
          playing: 'Reproduciendo...',
          showTimeSeries: 'Mostrar serie temporal',
        },
        form: {
          updateLeakSuccess: 'Actualizado con éxito.',
          updateLeakError: 'Error al actualizar la fuga. Por favor, inténtelo de nuevo más tarde.',
          alertTitle: {
            partOne: 'El registro de la fuga está en',
            partTwo: 'modo solo lectura',
            partThree: '.',
          },
          alertDialog: {
            dialogOne: 'Sin embargo, se pueden modificar las siguientes propiedades:',
            dialogTwo: 'Estado de la acción',
          },
          GPSLocation: 'Ubicación GPS',
          completedOn: 'Completado el:',
          lastSaved: 'Última guardado:',
        },
        images: {
          removeSuccess: 'Imagen eliminada con éxito.',
          removeError: 'No se puede eliminar la imagen del registro de fugas. Por favor, inténtelo de nuevo más tarde.',
          text: '¿Estás seguro de que quieres eliminar esta imagen?',
        },
        index: {
          loadingError: 'Error al cargar el registro de fugas. Por favor, inténtelo de nuevo.',
          unsavedMessage: "Al presionar 'Ok', confirma que todos los cambios no guardados se eliminarán",
          notAnAuditor: 'No eres auditor',
          anEssential: 'Estás en un plan esencial',
          showHistory: 'Mostrar historial',
        },
        massFlow: {
          recalculating: 'Recalculando Flujo/Pérdida según los parámetros de fuga...',
          warning:
            'Los valores de entrada han cambiado o el cálculo está desactualizado, presione "Recalcular" para actualizar los valores de Flujo y Pérdida',
          calculatingError: 'Error al calcular el flujo de masa. Por favor, inténtelo de nuevo más tarde.',
          noValue: 'Sin valor',
          calculatingSuccess: 'Flujo/Pérdida recalculado',
          recalculate: 'Recalcular',
          calculatorVersion: 'Versión de cálculo:',
          noteDialog: {
            note: 'Nota:',
            dialogOne: 'Cambiar los parámetros de la fuga actualizará el cálculo de Flujo/Pérdida. Verificar',
            dialogTwo: 'Información de cálculo',
            dialogThree: 'página para más información',
          },
        },
        unsavedWarningDialog: {
          recordChanged: 'El registro de la fuga ha sido modificado',
          close: 'Cerrar',
          unsavedChanges: 'Este registro tiene cambios no guardados. Por favor, considere guardar esos cambios o eliminarlos por completo.',
          discardChanges: 'Descartar cambios',
        },
      },
      leakRecordCreate: {
        form: {
          deviceIDIsRequired: 'Se requiere ID del dispositivo',
          dbPeakIsRequired: 'Se requiere el DB de pico medido',
          dbMin: 'El DB de pico medido debe ser mayor o igual a 0',
          dbMax: 'El DB de pico medido debe ser menor o igual a 95',
          dbError: 'El DB de pico medido debe ser un número',
          imageUploadError: 'Error al cargar la imagen. Por favor, inténtelo de nuevo más tarde.',
          createLeakSuccess: 'Registro de fuga añadido con éxito',
          createLeakError: 'Error al crear una cuenta. Por favor, inténtelo de nuevo más tarde.',
          basicInfo: 'Información básica',
          flowInput: 'Entrada de flujo',
          mainGas: 'Gas/Componente principal de interés',
          selectComponent: 'Seleccionar componente',
          selectLeakType: 'Seleccionar tipo de fuga',
          mightBeSomethingHere: 'no seguro aún',
          energyLoss: 'Pérdida de energía',
          selectCompressorType: 'Seleccionar tipo de compresor',
          noRoutes: 'No se encontraron rutas',
          noEquipment: 'No se encontró equipo',
          noLocations: 'No se encontraron ubicaciones',
          energyCost: 'Costo de energía',
          audioRecording: 'Grabación de Audio',
          leakSourceEquipment: 'Equipo fuente de la fuga',
          selectComponentType: 'Seleccionar tipo de componente',
          selectService: 'Seleccionar servicio',
          locationAndUser: 'Ubicación y usuario',
          leakEventStatus: 'Estado del evento de fuga',
          low: 'Bajo',
          medium: 'Medio',
          high: 'Alto',
          open: 'Abrir',
          complete: 'Completo',
          reset: 'Restablecer',
        },
        images: {
          leakSourceImageCapture: 'Captura de imagen de la fuente de fuga',
          add: 'Añadir',
        },
        index: {
          title: 'Crear registro de fuga',
        },
      },
      leakRecordsList: {
        filters: {
          from: 'Desde',
          to: 'Hasta',
          qualifier: 'Calificador',
        },
        modalAssignUsers: {
          assignLeakRecord: 'Asignar registro de fuga',
          selectAUserToAssign: 'Seleccione un usuario que debería ser asignado a los registros seleccionados',
          filterByName: 'Filtrar por nombre',
          filterByEmployeeName: 'Filtrar por nombre de empleados',
          unexpectedError: 'Ocurrió un error inesperado',
          noUserFound: 'No se encontraron usuarios',
          assigningActionForSelectedRecords: 'Asignando acción para los registros seleccionados...',
        },
        tableHead: {
          youAreSelecting: 'Estás seleccionando:',
          visibleRecords: 'registros visibles',
          andAllUnloadedRecords: 'y todos los registros no cargados.',
          allRecordsWillBeLoaded: 'Todos los registros serán cargados y seleccionados.',
          thisCanTakeAWhile: 'Esto puede tardar un tiempo si tienes muchos registros.',
        },
        tableBody: {
          noRecordsToDisplay: 'No hay registros para mostrar.',
        },
        toolBar: {
          assign: 'Asignar',
          onEssentialPlan: 'Estás en un plan esencial. Por favor, contacta con support@prosaris.ca',
          exportPDF: 'Exportar PDF',
          needAdminLicense:
            'Necesitas una licencia de administrador para acceder a esta función. Por favor, contacta con support@prosaris.ca',
          create: 'Crear',
          deleteRecordMessage: '¿Estás seguro de que quieres eliminar el registro seleccionado?',
          deleteRecordMessagePlural: '¿Estás seguro de que quieres eliminar los registros seleccionados?',
          permissionDenied: '¡Permiso denegado!',
          notAllowedToDelete:
            'Has seleccionado un registro de alguien que no tienes permitido eliminar. Por favor, quítalo de la selección.',
          generatingPDF: 'Generando PDF...',
          cannotGeneratePDF:
            'Desafortunadamente, no podemos exportar {{numberSelected}} registros en un documento PDF ahora mismo. Por favor, selecciona hasta {{limitRecords}} registros.',
          cannotExportMessage: 'No se pueden exportar {{numberSelected}} registros',
        },
        index: {
          cannotLoadLeakRecord: 'Lo siento, no podemos cargar el registro de fuga en este momento',
          successfullyAssignedAllRecords: 'Todos los registros asignados con éxito',
          actionRecordAssigned: '¡Registro de acción asignado!',
          actionRecordAssignedPlural: '¡Registros de acción asignados!',
          errorWhileAssigningLeak: 'Error al asignar la fuga. Por favor, inténtalo de nuevo más tarde.',
          successfullyDeletedAllRecords: 'Todos los registros eliminados con éxito',
          successfullyDeletedRecord: '{{length}} registro de fuga eliminado con éxito',
          successfullyDeletedRecordPlural: ' {{length}} registros de fuga eliminados con éxito',
          errorOnRecordsDelete: 'Error al eliminar registros. Por favor, inténtelo de nuevo más tarde',
          leakPreview: 'Vista previa de la fuga',
          leakRecord: 'Registro de fuga: ',
        },
      },
      tagList: {
        resetTag: 'Restablecer etiqueta',
        dataExport: {
          smartLeakTagsFormat: 'Formato de etiquetas de fuga inteligentes',
          smartLeakTagsDescription: 'Contiene campos predeterminados como los presentes en la página de etiquetas de fuga inteligentes',
          standardLeakRecordFormat: 'Formato estándar de registro de fuga',
          standardLeakRecordDescription: 'Contiene todos los campos relacionados con los registros de fugas',
        },
        filters: { smartLeakTagFilters: 'Filtros de etiquetas de fuga inteligentes' },
        index: {
          cannotLoadTags: 'Lo siento, no podemos cargar la etiqueta de fuga inteligente en este momento',
          updateLeakSuccess: 'Restablecimiento de la etiqueta de fuga inteligente exitoso.',
          updateLeakError: 'Error al restablecer la etiqueta de fuga inteligente. Por favor, inténtelo de nuevo más tarde.',
          bulkEditSuccess: 'Restablecimiento exitoso de {{len}} etiqueta de fuga inteligente',
          bulkEditSuccessPlural: 'Restablecimiento exitoso de {{len}} etiquetas de fuga inteligentes',
          bulkEditError: 'Error al restablecer las etiquetas de fuga inteligentes. Por favor, inténtelo de nuevo más tarde.',
          tagsFileName: 'Etiquetas de fuga inteligentes',
          leakFileName: 'Registros de fuga',
        },
        tableBody: {
          title: 'Por favor, confirme el restablecimiento de la etiqueta',
          alertDialog: {
            dialogOne: 'Estás tratando de',
            dialogTwo: 'restablecer la etiqueta',
            dialogThree: 'de un registro de fuga con',
            dialogFour: 'Estado de la acción: ABIERTO',
            dialogFive: '¿Quieres continuar?',
          },
        },
        toolBar: {
          resetTags: 'Restablecer etiquetas',
          title: 'Por favor, confirme el restablecimiento masivo de las etiquetas',
          alertDialog: {
            dialogOne: 'Su selección contiene',
            dialogTwo: 'registro con',
            dialogTwoPlural: 'registros con',
            dialogThree: 'Estado de la acción: ABIERTO',
            dialogFour: '¿Quieres continuar?',
          },
          exportFormatCSV: 'Seleccione un formato de exportación para CSV',
          exportFormatPDF: 'Seleccione un formato de exportación para PDF',
        },
      },
      myDashboard: {
        assignedActions: {
          index: {
            errorMessage: 'Error al cargar las acciones asignadas. Por favor, inténtelo de nuevo.',
          },
        },
        charts: {
          chartFilters: {
            availableCharts: 'Gráficos disponibles',
            selectAChart: 'Seleccione un gráfico...',
            chartFilters: 'Filtros de gráficos',
          },
          charts: {
            errorMessages: {
              errorMessageOne: 'Error al cargar los registros de fugas. Por favor, inténtelo de nuevo.',
              errorMessageTwo: 'Por favor, seleccione una fecha anterior a la fecha de finalización.',
              errorMessageThree: 'Por favor, seleccione un período de fecha inferior a 6 meses.',
              errorMessageFour: 'Por favor, seleccione una fecha posterior a la fecha de inicio.',
            },
            charts: 'Gráficos',
            chartAlreadyExists: '¡Este gráfico ya existe!',
          },
          filters: {
            select: 'Seleccionar...',
            startDate: 'Fecha de inicio',
            endDate: 'Fecha de finalización',
          },
          utils: {
            chartsTitles: {
              FLOW_BY_LEAK: (unit) => `Flujo promedio por categoría de fuga (${unit})`,
              LOSS_BY_LEAK: (currency) => `Pérdida por categoría de fuga (${currency})`,
              TAGS_BY_LEAK: 'Etiquetas por categoría de fuga',
              AVG_FLOW_BY_LEAK: (unit) => `Flujo promedio por categoría de fuga (${unit})`,
              SAVING_OPT_BY_REPAIR: (currency) => `Oportunidad de pérdida y ahorro por tiempo de reparación (${currency})`,
              FLOW_REPAIR_TIME: (unit) => `Flujo por tiempo de reparación (${unit})`,
              LOSS_REPAIR_TIME: (currency) => `Pérdida por tiempo de reparación (${currency})`,
              FLOW_REPAIR_PROG: (unit) => `Progreso del flujo – Reparado v. No reparado (${unit})`,
              TOTAL_REPAIR_PROG: (currency) => `Progreso de la pérdida – Reparado v No reparado (${currency})`,
              FLOW_BY_EQUIP_COMP: (unit) => `Flujo por equipo/componente (${unit})`,
              LOSS_BY_EQUIP_COMP: (currency) => `Pérdida por equipo/componente (${currency})`,
              TAGS_BY_EQUIP_COMP: 'Etiquetas por equipo/componente',
            },
            datasetLabel: {
              totalLeakFlow: 'Flujo total de fuga encontrado en',
              totalLoss: 'Total de pérdida en',
              totalRecords: 'Total de registros encontrados en',
              avgLeakFlow: 'Flujo promedio de fuga encontrado en',
              totalLossIn: 'Pérdida total en',
              flowByRepairTime: 'Flujo por tiempo de reparación en',
              lossByRepairTime: 'Pérdida por tiempo de reparación en',
              flowByRepairProgress: 'Flujo encontrado por progreso de reparación en',
              recordsByCompletion: 'Registros por finalización en',
              flowByEquipment: 'Flujo encontrado por equipo en',
              totalLossByEquipment: 'Pérdida total por equipo en',
              totalRecordsByEquipment: 'Registros totales por equipo en',
            },
            labels: {
              low: 'Bajo',
              medium: 'Medio',
              high: 'Alto',
              equipment: 'Equipo',
              savings: 'Ahorros',
              opportunity: 'Oportunidad',
              loss: 'Pérdida',
              repaired: 'Reparado',
              unrepaired: 'No reparado',
              LESS_15_DAYS: 'Menos de 15 días',
              DAYS_16_TO_30: '15 a 30 días',
              DAYS_31_TO_60: '31 a 60 días',
              DAYS_61_TO_90: 'Más de 60 días',
            },
          },
        },
        index: {
          assignedActions: 'Acciones asignadas a usted',
        },
      },
      export: {
        imageUrlOne: 'URL de la imagen de fuga 1',
        imageUrlTwo: 'URL de la imagen de fuga 2',
        leakTag: 'Etiqueta de fuga inteligente/Referencia de trabajo',
        per: 'por ',
      },
    },
    languages: {
      English: 'Inglés',
      Chinese: 'Chino',
      Spanish: 'Español',
      French: 'Francés',
      German: 'Alemán',
      Norwegian: 'Noruego',
      Italian: 'Italiano',
      Turkish: 'Turco',
    },
  },
};
