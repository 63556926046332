import { createContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { DEFAULT_LABELS } from 'src/constants/recordFields';
import { PERFORMANCE_INDICATOR_LABELS } from 'src/constants/enpisFields';
import { LABELS } from 'src/constants/compressorFields';
import Unit from 'src/constants/unit';

const UnitContext = createContext({
  unit: Unit.METRIC,
  labels: {},
  enpisLabels: {},
  compressorLabels: {},
  showUnitToggle: false,
  setShowUnitToggle: () => {},
  setUnit: () => {},
  setOffset: () => {},
  offset: {},
});

export function UnitProvider({ children }) {
  const { t } = useTranslation();
  const languageLabels = t('constants.recordFields', { returnObject: true });
  const enpisLanguageLabels = t('constants.enpisFields.labels', { returnObject: true });
  const compressorLanguageLabels = t('constants.compressorFields.labels', { returnObject: true });
  const [unit, setUnit] = useState({ value: Unit.METRIC, changed: false });
  const [showUnitToggle, setShowUnitToggle] = useState(false);
  const [labels, setLabels] = useState({ languageLabels });
  const [enpisLabels, setEnpisLabels] = useState({ enpisLanguageLabels });
  const [compressorLabels, setCompressorLabels] = useState({ compressorLanguageLabels });
  const [offset, setOffset] = useState({});

  function handleUnitChange(newUnit) {
    if (newUnit === Unit.METRIC) {
      setLabels(languageLabels);
      setEnpisLabels(enpisLanguageLabels);
      setCompressorLabels(compressorLanguageLabels);
    } else {
      const languageImperialLabels = t('constants.IMPERIAL_LABELS', { returnObject: true });
      const enpisLanguageImperialLabels = t('constants.enpisFields.labelsImperial', { returnObject: true });
      const compressorLanguageImperialLabels = t('constants.compressorFields.labelsImperial', { returnObject: true });

      const unitLabels = {};
      const enpisLabels = {};
      const compressorLabels = {};

      const keys = Object.keys(DEFAULT_LABELS);
      const enpisKeys = Object.keys(PERFORMANCE_INDICATOR_LABELS);
      const compressorKeys = Object.keys(LABELS);

      const maxLength = Math.max(keys.length, enpisKeys.length, compressorKeys.length);

      for (let i = 0; i < maxLength; i++) {
        if (i < keys.length) {
          const key = keys[i];
          unitLabels[key] = newUnit === Unit.IMPERIAL && languageImperialLabels[key] ? languageImperialLabels[key] : languageLabels[key];
        }

        if (i < enpisKeys.length) {
          const enpisKey = enpisKeys[i];
          enpisLabels[enpisKey] =
            newUnit === Unit.IMPERIAL && enpisLanguageImperialLabels[enpisKey]
              ? enpisLanguageImperialLabels[enpisKey]
              : enpisLanguageLabels[enpisKey];
        }

        if (i < compressorKeys.length) {
          const compressorKey = compressorKeys[i];
          compressorLabels[compressorKey] =
            newUnit === Unit.IMPERIAL && compressorLanguageImperialLabels[compressorKey]
              ? compressorLanguageImperialLabels[compressorKey]
              : compressorLanguageLabels[compressorKey];
        }
      }

      setLabels(unitLabels);
      setEnpisLabels(enpisLabels);
      setCompressorLabels(compressorLabels);
    }

    setUnit({ value: newUnit, changed: true });
  }

  function handleOffset(key, value) {
    const update = { ...offset };
    update[key] = value;
    setOffset(update);
  }

  useEffect(() => {
    handleUnitChange(unit.value);
  }, [unit.value, t]);

  const contextValue = useMemo(() => ({
    unit: unit.value,
    unitChanged: unit.changed,
    labels,
    enpisLabels,
    compressorLabels,
    setUnit: handleUnitChange,
    showUnitToggle,
    setShowUnitToggle,
    setOffset: handleOffset,
    offset,
  }));

  return <UnitContext.Provider value={contextValue}>{children}</UnitContext.Provider>;
}

UnitProvider.propTypes = {
  children: PropTypes.node,
};

export default UnitContext;
